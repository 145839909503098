html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html {
	font-size: 10px;
	height: 100%;
  background-color: #153B5F;
}
body {
	line-height: 1;
	height: 100%;
}
#root {
	height: 100%;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

h1 {
	font-size: 24px;
}
h2 {
	font-size: 22px;
}
h3 {
	font-size: 20px;
}
h4 {
	font-size: 16px;
}

/* Styles for the app */
a {
	color: #000;
}

.mktoForm {
	width: 100% !important;
	margin: 0 auto;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	justify-self: center;
	align-self: center;
}

.mktoFormRow {
	padding: 10px 0 !important;
	margin: 0 auto !important;
}

.mktoButtonRow {
	padding: 10px 0 !important;
	margin: 0 auto !important;
}

.mktoButtonWrap {
	width: 100% !important;
	background-color: #006BE6 !important;
}

.mktoForm button {
	width: 100% !important;
	color: #FFF !important;
	background-color: #006BE6 !important;
	background-image: none !important;
	border: none !important;
	border-radius: 8px !important;
	width: 300px !important;
	font-size: 24px !important;
	height: 50px !important;
}

.mktoAsterix {
	display: none !important;
}

.mktoForm input {
	width: 300px !important;
	height: 50px !important;
	border-radius: 8px !important;
	display: block !important;
	font-size: 24px !important;
	line-height: 24px !important;
}

.mktoLabel {
	color: #FFF;
	font-size: 24px;
	width: 200px !important;
	line-height: 24px !important;
	padding-top: 24px !important;
}

.logo {
  padding-top: 15px;
}

.MuiPaper-root {
	font-size: 2rem !important;
	justify-content: center;
}

.goBack {
	font-size: 4rem;
	color: #FFF !important;
	text-decoration: none;
	padding: 10px;
}

.goBack a {
	color: #FFF !important;
}

@media screen and (max-width: 768px) {
  .logo img {
    height: 50px;
    width: auto;
  }

  .logo > header {
    font-size: 1.9rem;
  }

  .tiles {
    height: 365px !important;
    width: 290px !important;
  }
}